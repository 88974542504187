import React from 'react';
import Layout from '../layout/layout';

import IllustrationMailBox from '../images/icons/newsletter-confirmation/illustration-mailbox.svg';
import IllustrationCloud from '../images/icons/newsletter-confirmation/illustration-cloud.svg';
import IllustrationTrees from '../images/icons/newsletter-confirmation/illustration-trees.svg';

const returnBack = () => window.history.back();

const NewsletterConfirmation = () => (
  <Layout invertHeader={true}>
  	<section className="newsletter-confirmation">
	  	<div className="container text-left">
	  		<div className="row">
		  		<div className="col-sm-7 col-md-7 col-lg-8 offset-sm-2 offset-md-3 offset-lg-3">
				    <h1>Almost done...</h1>
				    <p>We've sent an email to the address you entered.</p>
				    <p>To complete your subscription, please click the link in the email.</p>
				    <p>If you don't see the email, please check your junk or spam folder</p>
					<button onClick={() => returnBack()} className="btn btn-sm btn-blue">Return to the previous page</button>
		    	</div>
	    	</div>
	    	<img src={IllustrationMailBox} className="illustration-mail-box" alt="mail box" />
			<img src={IllustrationCloud} className="illustration-cloud" alt="cloud" />
			<img src={IllustrationTrees} className="illustration-trees" alt="trees" />
	    </div>
    </section>
  </Layout>
)

export default NewsletterConfirmation;
